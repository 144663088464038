exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-content-js": () => import("./../../../src/pages/main-content.js" /* webpackChunkName: "component---src-pages-main-content-js" */),
  "component---src-pages-sidebar-js": () => import("./../../../src/pages/sidebar.js" /* webpackChunkName: "component---src-pages-sidebar-js" */),
  "component---src-pages-sub-category-based-content-js": () => import("./../../../src/pages/sub-category-based-content.js" /* webpackChunkName: "component---src-pages-sub-category-based-content-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

